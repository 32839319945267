var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headersPrep,"items":_vm.items,"loading":_vm.loading,"options":_vm.pagination,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"custom-sort":_vm.customSort,"footer-props":{
      itemsPerPageOptions: [5, 10, 25, 50, 100],
      itemsPerPageText: _vm.$t('TABLE.ROWS_PER_PAGE')
    },"must-sort":"","id":"main","hide-default-header":"","item-key":_vm.itemKey,"expanded":_vm.expanded,"single-expand":"","loader-height":"2"},on:{"update:options":function($event){return _vm.$emit('update:pagination', $event)},"update:sort-by":function($event){return _vm.$emit('update:sort-by-prop', $event)},"update:sort-desc":function($event){return _vm.$emit('update:sort-desc-prop', $event)}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-progress-circular',{attrs:{"size":"18","width":"2","color":"primary","indeterminate":"","id":"progress"}}),_c('span',[_vm._v(_vm._s(_vm.$t("TABLE.LOADING")))])]},proxy:true},{key:"no-data",fn:function(){return [_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("TABLE.EMPTY")))])]},proxy:true},{key:"header",fn:function({ props }){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:[
              'column',
              header.sortable !== false ? 'sortable' : '',
              !!_vm.sortDesc ? 'desc' : 'asc',
              header.value === _vm.sortBy ? 'active' : '',
              'text-xs-left'
            ],attrs:{"role":"columnheader"},on:{"click":function($event){(header.sortable === undefined || header.sortable) &&
                _vm.changeSort(header.value)}}},[_c('span',[_vm._v(_vm._s(_vm.$t(header.text)))]),(header.sortable)?_c('v-icon',{staticClass:"v-data-table-header__icon",attrs:{"small":""}},[_vm._v("arrow_upward")]):_vm._e()],1)}),0),_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,staticClass:"text-left"},[(header.filterable && !header.filterValues)?_c('v-text-field',{staticClass:"txt_ics",attrs:{"name":'filter-' + header.value,"id":'filter-' + header.value},on:{"input":function($event){return _vm.filter($event, header.value)}}}):_vm._e(),(!header.filterable)?_c('div',{staticStyle:{"visibility":"hidden","width":"60px"}}):_vm._e(),(header.filterable && header.filterValues)?_c('v-select',{staticClass:"txt_ics",attrs:{"items":header.filterValues,"name":'filter-' + header.value,"id":'filter-' + header.value,"menu-props":{ transition: 'scale-transition' }},on:{"input":function($event){return _vm.filter($event, header.value)}},model:{value:(_vm.defaultSelected[header.value]),callback:function ($$v) {_vm.$set(_vm.defaultSelected, header.value, $$v)},expression:"defaultSelected[header.value]"}}):_vm._e()],1)}),0)])]}},{key:"item",fn:function(props){return [_c('tr',{class:[
          'main-row',
          { clickable: _vm.subHeaders.length > 0 },
          { zebra: props.index % 2 === 0 }
        ],on:{"click":function($event){_vm.subHeaders.length > 0 && _vm.expandRow(props.item)}}},[_vm._t("rowContent",null,{"item":props.item})],2)]}},{key:"expanded-item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"sub-cell",attrs:{"colspan":props.headers.length}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-header":"","hide-default-footer":"","headers":_vm.subHeaders,"items":[props.item]},scopedSlots:_vm._u([{key:"item",fn:function(subProps){return [_vm._t("subRowContent",null,{"item":subProps.item})]}},{key:"header",fn:function({ props: { headers } }){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.text,class:['column', 'text-xs-left'],attrs:{"role":"columnheader"}},[_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")])}),0)])]}}],null,true)})],1)])]}},{key:"pageText",fn:function({ pageStart, pageStop, itemsLength }){return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" "+_vm._s(_vm.$t("TABLE.OF"))+" "+_vm._s(itemsLength)+" ")]}}],null,true)}),_c('div',{staticClass:"pb-2 hidden-sm-and-down",attrs:{"id":"pagination"}},[_c('v-pagination',{attrs:{"length":_vm.pages,"total-visible":"6"},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }