
import Vue from "vue";
import logger from "@/icslogger";
export default Vue.extend({
  name: "grouped-select",
  data() {
    return {
      selected: this.selectedItems || []
    };
  },
  watch: {
    selected() {
      this.$emit("selectionChanged", this.selected);
      logger.info("Realestate filter changed", JSON.stringify(this.selected));
    },
    selectedItems() {
      this.selected = this.selectedItems;
    }
  },
  computed: {
    itemsGrouped(): any[] {
      if (!this.groupKey) {
        return this.items;
      }
      const groupKey = this.groupKey;
      const groupedItems = this.items.reduce((objectsByKeyValue, obj) => {
        const value = obj[groupKey];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});
      let groupedItemArray: any[] = [];
      for (const group of Object.keys(groupedItems)) {
        groupedItemArray.push({ header: group });
        const groupMembers = groupedItems[group];
        groupedItemArray = groupedItemArray.concat(groupMembers);
        groupedItemArray.push({ divider: true });
      }
      return groupedItemArray;
    }
  },
  methods: {
    isSelected(item: any): boolean {
      const index = (this.selected as any[]).indexOf(item[this.itemValue]);
      return index > -1;
    },
    remove(item: any): void {
      const index = (this.selected as any[]).indexOf(item[this.itemValue]);
      if (index >= 0) {
        this.selected.splice(index, 1);
      }
    }
  },
  props: {
    items: Array as () => any[],
    selectedItems: Array as () => any[],
    itemText: String,
    itemValue: String,
    groupKey: String,
    label: String,
    icon: String
  }
});
