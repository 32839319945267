import { LoggerService } from "@/services";
export default {
  debug(action: string, message: string) {
    LoggerService.postLogMessage({ action, message, level: "DEBUG" });
  },
  info(action: string, message: string) {
    LoggerService.postLogMessage({ action, message, level: "INFO" });
  },
  warn(action: string, message: string) {
    LoggerService.postLogMessage({ action, message, level: "WARN" });
  },
  error(action: string, message: string) {
    LoggerService.postLogMessage({ action, message, level: "ERROR" });
  }
};
