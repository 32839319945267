
import Vue from "vue";
import i18n from "@/plugins/i18n";
import debounce from "lodash/debounce";
import logger from "@/icslogger";
import moment from "moment";
import { resolve } from "@/mixins";
export default Vue.extend({
  name: "base-table",
  data() {
    const filters: any = {};
    const selectModels: string[] = [];
    for (const header of this.headers) {
      if (header.value !== "") {
        filters[header.value] = "";
      }
      if (header.filterValues) {
        selectModels[header.value] = i18n.t("FILTER.CHOOSE").toString();
      }
    }
    const expanded: any[] = [];
    return {
      filters,
      expanded,
      defaultSelected: selectModels,
      sortDesc: this.sortDescProp,
      sortBy: this.sortByProp
    };
  },
  beforeUpdate: function() {
    for (let header of this.headers) {
      if (
        header.filterValues &&
        !header.filterValues.includes(this.defaultSelected[header.value])
      ) {
        this.defaultSelected[header.value] = i18n.t("FILTER.CHOOSE").toString();
      }
    }
  },
  computed: {
    headersPrep(): any[] {
      return this.headers.map(header => {
        const newHeader = Object.assign({}, header);
        if (newHeader.filterValues) {
          newHeader.filterValues = [i18n.t("FILTER.CHOOSE")];
          newHeader.filterValues = newHeader.filterValues.concat(
            header.filterValues
          );
        }
        return newHeader;
      });
    },
    pages(): number {
      if (
        this.pagination.itemsPerPage == null ||
        this.pagination.serverItemsLength == null
      ) {
        return 0;
      }
      return Math.ceil(
        this.pagination.serverItemsLength / this.pagination.itemsPerPage
      );
    }
  },
  methods: {
    expandRow(value: any) {
      const prevRow = this.expanded.pop();
      if (prevRow !== value) {
        this.expanded.push(value);
      }
    },
    changeSort(column: any): void {
      if (this.sortBy === column) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = column;
        this.sortDesc = false;
      }
    },
    customSort(items: any[], index: any[], isDesc: any[]): any[] {
      // console.error(items,index, isDesc);
      items.sort((a: any, b: any) => {
        const aValue: any = resolve(index[0], a);
        const bValue: any  = resolve(index[0], b);
        if (index[0] === "date" || index[0] === "createdAt" || index[0] === "creationDate" || index[0] === "activatedAt" || index[0] == "lastLogin") {
          if (!isDesc[0]) {
            if (aValue === "") {
              return -1;
            }
            if (bValue === "") {
              return 1;
            }
            return moment(aValue).isAfter(bValue) ? 1 : -1;
          } else {
            if (aValue === "") {
              return 1;
            }
            if (bValue === "") {
              return -1;
            }
            return !moment(aValue).isAfter(bValue) ? 1 : -1;
          }
        } else {
          if (!isDesc[0]) {
            if (aValue == null || aValue == "") {
              return -1;
            } 
            if (bValue == null || bValue == "") {
              return 1;
            }
              return (
                aValue.toString().trim().toLowerCase().localeCompare(bValue.toString().trim().toLowerCase())
              );
            // return a[index[0]].trim() < b[index[0]].trim() ? -1 : 1;
          } else {
             if (aValue == null || aValue == "") {
              return 1;
            } 
            if (bValue== null || bValue == "") {
              return -1;
            }
             return (
                bValue.toString().trim().toLowerCase().localeCompare(aValue.toString().trim().toLowerCase())
              );
            // return b[index[0]].toLowerCase().trim() < a[index[0]].trim() ? -1 : 1;
          }
        }
      });
      return items;
    },
    filter(value: any, column: any): void {
      if (column !== "") {
        if (value === i18n.t("FILTER.CHOOSE")) {
          value = "";
        }
        this.filters[column] = value;
        this.$emit("filterChanged", this.filters);
        this.logFilter(this.filters);
      }
    },
    logFilter: debounce(function(this: any, filters: any): void {
      logger.info("Filter table " + this.title, JSON.stringify(filters));
    }, 1000)
  },
  props: {
    loading: Boolean,
    headers: Array as () => any[],
    subHeaders: Array as () => any[],
    items: Array as () => any[],
    pagination: Object,
    itemKey: String,
    sortByProp: String,
    sortDescProp: Boolean
  }
});
